a {
  text-decoration: none;
}
.footerGAC {
  background: #003893;
  color: #fff;
}
.footer-text {
  font-size: 14px;
  color: #003893;
}

.rnt-tex {
  text-align: center;
  font-size: 13px;
  color: #666;
  margin: 1em;
}
.lines {
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  width: 100%;
  z-index: -1;
}
.lines .line {
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  left: 50%;
  background: rgba(255, 255, 255, 0.07);
  overflow: hidden;
}
.lines .line::after {
  content: "";
  display: block;
  position: absolute;
  height: 15vh;
  width: 100%;
  top: -50%;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 75%,
    #ffffff 100%
  );
  animation: run 5s 0s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(1) {
  margin-left: -25%;
}
.lines .line:nth-child(1)::after {
  animation-delay: 2s;
}
.lines .line:nth-child(3) {
  margin-left: 25%;
}
.lines .line:nth-child(3)::after {
  animation-delay: 2.5s;
}

@keyframes run {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}
.animation-wrap {
  float: left;
  display: inline;
}

.gac {
  width: 160px;
  height: 160px;
  position: relative;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
}

.adelante {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.atras img {
  width: 160px;
  height: 160px;
}

.atras {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.adelante {
  width: 100%;
}

.atras {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  color: #fff;
  font-family: "open sans";
}

.animation-wrap:hover .gac {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.gac2 {
  background-image: url("/public/GAC_footer.png");
  background-size: cover;
}
