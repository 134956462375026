body {
  background: url("/public/login_priority.jpg") fixed;
  background-size: cover;
  position: relative;
}
.bg-navbar {
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(6px);
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-icons {
  display: flex;
  gap: 10px;
  width: 10%;
}
.navbar-icons .icon {
  font-size: 28px;
  color: #003893;
}

.whatsapp-float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 24px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: all 0.3s;
}

.whatsapp-float i {
  font-size: 2rem;
}

.whatsapp-float:hover {
  color: #fff;
  transform: scale(1.1);
  outline: 1px solid;
}

.darkBG {
  background-color: rgba(0, 56, 147, 0.5);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  width: 100%;
  height: 350vh;
  z-index: 1;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
.bg-light {
  padding: 1em;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-modal {
  height: fit-content;
  box-shadow: 0px 187px 75px rgba(0, 0, 0, 0.01),
    0px 105px 63px rgba(0, 0, 0, 0.05), 0px 47px 47px rgba(0, 0, 0, 0.09),
    0px 12px 26px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  border-radius: 1em;
  width: 500px;
  animation: bounce-top 0.9s linear both;
  background: rgba(255, 255, 255, 1);
  backdrop-filter: blur(10px);
  margin: 5em;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 2em;
}

.payment--options {
  width: calc(100% - 40px);
  display: grid;
  grid-template-columns: 33% 34% 33%;
  gap: 20px;
  padding: 10px;
}

.payment--options button {
  height: 55px;
  background: #f2f2f2;
  border-radius: 11px;
  padding: 0;
  border: 0;
  outline: none;
}

.payment--options button svg {
  height: 18px;
}

.separator {
  width: calc(100% - 20px);
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 10px;
  color: #8b8e98;
  margin: 0 10px;
}

.separator > p {
  word-break: keep-all;
  display: block;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  margin: auto;
  color: #003893;
}

.separator .line {
  display: inline-block;
  width: 100%;
  height: 1px;
  border: 0;
  background-color: #003893;
  margin: auto;
}

.credit-card-info--form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.input_container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.split {
  display: grid;
  grid-template-columns: 4fr 4fr;
  gap: 15px;
}

.split input {
  width: 100%;
}

.input_label {
  font-size: 14px;
  color: #003893;
  font-weight: 600;
}

.input_field {
  width: auto;
  height: 40px;
  padding: 0 0 0 16px;
  border-radius: 9px;
  outline: none;
  background-color: #f2f2f2;
  border: 1px solid #e5e5e500;
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
}

.input_field:focus {
  border-color: transparent;
  box-shadow: 0px 0px 0px 3px #ffcf04;
  color: #003893;
}

.purchase--btn {
  height: 55px;
  background: #ffcf04;
  border-radius: 11px;
  border: 0;
  outline: none;
  color: #003893;
  font-size: 16px;
  font-weight: 700;
  /* background: linear-gradient(180deg, #363636 0%, #1B1B1B 50%, #000000 100%); */
  box-shadow: 0px 0px 0px 0px #ffffff, 0px 0px 0px 0px #ffcf04;
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
}

.purchase--btn:hover {
  box-shadow: 0px 0px 0px 2px #ffffff, 0px 0px 0px 4px #ffcf04;
}

/* Reset input number styles */
.input_field::-webkit-outer-spin-button,
.input_field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input_field[type="number"] {
  -moz-appearance: textfield;
}
img {
  animation: scale-up-center 0.4s;
}
.custom-notification {
  width: fit-content;
  min-width: 520px;
}

.accepTerms {
  background: #ffcf04;
  border: none;
  padding: 0.4em 3em;
  border-radius: 5px;
  font-weight: 600;
  margin: 0.6em 0.3em;
}
.notAccept {
  background: #fff;
  color: #252525;
  text-decoration: none;
  border: 1px dashed;
  padding: 0.4em 2em;
  border-radius: 5px;
  opacity: 0.8;
}
.btnInfo {
  position: fixed;
  top: 40%;
  left: 0;
  writing-mode: vertical-rl;
  background: #ffcf04;
  color: #003893;
  border: none;
  border-radius: 10px 0 0 10px;
  padding: 1em 0.5em;
  font-weight: 700;
  white-space: nowrap;
  transform: rotate(180deg);
  letter-spacing: 0.1em;
  cursor: pointer;
  transition: width 0.3s;
  width: 40px;
  height: 220px;
  text-align: center;
  z-index: 9999;
  transition: width 2s;
}
.show-info {
  width: 310px;
  margin-top: 61px;
  background: #fff;
  position: fixed;
  top: 20%;
  left: 41px;
  color: #666;
  padding: 2em;
  text-align: justify;
  border-radius: 1em;
  z-index: 1;
}
@keyframes bounce-top {
  0% {
    transform: translateY(-45px);
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    transform: translateY(-24px);
    animation-timing-function: ease-in;
  }
  65% {
    transform: translateY(-12px);
    animation-timing-function: ease-in;
  }
  82% {
    transform: translateY(-6px);
    animation-timing-function: ease-in;
  }
  93% {
    transform: translateY(-4px);
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateY(0);
    animation-timing-function: ease-out;
    opacity: 1;
  }
}
@keyframes scale-up-center {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
.form-select {
  background-color: #f2f2f2;
  border: 1px solid #e5e5e500;
}
@media only screen and (max-width: 759px) {
  .form-modal {
    max-width: 100%;
    margin: 2rem 0rem 2rem 0rem;
  }
  img {
    animation: scale-up-center 0.6s;
  }
  .btnInfo {
    padding: 1em 0em;
    width: 27px;
    height: fit-content;
  }
}
